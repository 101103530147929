@import '~antd/dist/antd.min.css';
/* FIXME: antd-mobile で CJK フォントになるため上書き。 antd-mobile が PingFang SC, Hiragino Sans GB を指定しなくなったら (なくなるはず) 速やかに消去してください */

/*
"Helvetica Neue", iOSの欧文フォント 
  Arial, Windowsの欧文フォント 
  "Hiragino Kaku Gothic ProN", iOS和文フォント 
  "Hiragino Sans", iOS和文フォント 
  Meiryo, Windowsの和文フォント 
  sans-serif  総称 (Androidなど) 
  */
/* 
もしウェブフォントの容量が大きい問題を許容するのであれば、
「Noto Sans JP」も検討(1ウェイトあたり1.6MB) 
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
*/
html,
body {
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif !important;
  background: #f0f2f5;
}
